import { defineStyleConfig } from "@chakra-ui/react";

const Button = defineStyleConfig({
    baseStyle: {
        fontFamily: "inter, sans-serif",
        opacity: "1",
        borderRadius: 12,
        whiteSpace: "normal",
        wordWrap: "break-word",
        _loading: {
            opacity: "1"
        }
    },
    sizes: {
        sm: {
            fontSize: 13,
            h: 10,
            w: "auto",
            py: 3.5,
            px: 8
        },
        md: {
            fontSize: 16,
            h: "auto",
            w: "auto",
            py: 3.5,
            px: "2.4rem"
        }
    },
    variants: {
        outline: {
            bg: "white"
        },
        link: {
            color: "royalBlue.500",
            _active: {
                color: "royalBlue.500"
            }
        },
        textLink: {
            height: "auto",
            fontWeight: "normal",
            color: "ebonyClay.500",
            fontSize: 14,
            textDecoration: "underline",
            fontFamily: "body",
            padding: 0,
            _hover: {
                textDecoration: "underline"
            },
            _disabled: {
                color: "gray.600",
                _hover: {
                    cursor: "not-allowed",
                    textDecoration: "underline"
                }
            }
        }
    }
});

export default Button;
